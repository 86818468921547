import React from "react";
import { connect } from "react-redux";
import {
  getDateTimeFormate,
  formatMoney,
  Currency,
} from "../../sharedfiles/helper";
import BetId from "../BetId/BetId";
import {
  setBetId,
  setOpenDialog,
  betIdRecords,
} from "../BetHistory/betHistorySlice";
import { getBetAuditDetails } from "./playerBetHistorySlice";
import Table from "../Common/table/Table";
import { auditTableHeaders } from "../Common/data/mapData";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Tooltips from "../Common/Tooltip";
import { betAudit, betHistoryInfo } from "../../sharedfiles/tooltipMsgs"
class BettingHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asceending: true,
      userData: [],
      betId: "",
      betAuditData: [],
      openEventBox: false,
      openBetId: false,
      potentialPayout: ""
    };
    this.dateSorting = this.dateSorting.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.callEventDetails = this.callEventDetails.bind(this);
    this.updateDataToDialog = this.updateDataToDialog.bind(this);
  }

  dateSorting() {
    const getchedData = [...this.props.data];
    const sortData = getchedData.sort((a, b) => {
      const c = new Date(a.transactionDate);
      const d = new Date(b.transactionDate);
      return c - d;
    });
    return this.state.asceending ? sortData : sortData.reverse();
  }
  openDialogHandler(id, potentialPayout) {
    this.props.dispatch(setOpenDialog(true));
    this.props.dispatch(betIdRecords(id));
    this.setState({ potentialPayout: potentialPayout })
  }
  onDialogClose() {
    this.setState({ openEventBox: false });
  }
  callEventDetails = (userId) => {
    this.setState({ betAuditData: [] });
    if (userId !== 0) {
      this.props.dispatch(getBetAuditDetails(userId));
      this.setState({ betId: userId });
      this.setState({ openEventBox: true });
    }
  };
  updateDataToDialog() {
    const data = this.props.betAudit;
    const tBody = data &&
      data !== undefined &&
      data !== null &&
      Object.keys(data).length > 0 &&
      Array.isArray(data) &&
      data.length > 0
      ? data.map((item) => [
        item.userId,
        item.betSelection,
        item.betStatus,
        item.odds,
        formatMoney(item.payout),
        getDateTimeFormate(item.updatedDate),
      ])
      : [];
    const tableData = [auditTableHeaders, betAudit, ...tBody];
    this.setState({ betAuditData: tableData });
  }
  hadleBetId(val) {
    this.setState({ openBetId: val })
  }

  componentDidUpdate(prevProps) {
    if (this.props.betAudit !== prevProps.betAudit) {
      this.updateDataToDialog();
    }
  }

  componentDidMount() {
    this.hadleBetId(true);
  }
  componentWillUnmount() {
    this.hadleBetId(false)
  }

  render() {
    const showPermission = this.props.stakeTaxPermit && this.props.winTaxPermit
    return (
      <>
        {this.state.openEventBox ? (
          <Dialog__Main
            title={this.state.betId}
            dOpen={this.state.openEventBox}
            dClose={() => this.onDialogClose()}
            width={"xl"}
          >
            <Table tableData={this.state.betAuditData} hLen={4} vLen={2} />
          </Dialog__Main>
        ) : null}
        {this.state.openBetId ?
          <BetId
            data={
              this.props.betIdRecords.length > 0 ? this.props.betIdRecords : []
            }
            setBack={setBetId}
            potentialPayout={this.state.potentialPayout}
          /> : null
        }
        <div className='CMS-box '>
          <div className='CMS-table CMS-table-triped'>
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p>Bet ID</p>
                      <Tooltips info={betHistoryInfo.BetId} />
                    </div>
                  </th>
                  {/* <th style={{ textAlign: "center" }}>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p>Booking Code</p>
                      <Tooltips info={betHistoryInfo.bookingCode} />
                    </div>
                  </th> */}
                  <th style={{ textAlign: "center" }}>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p>Short Bet ID</p>
                      <Tooltips info={betHistoryInfo.shorbetId} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Event Name</p>
                      <Tooltips info={betHistoryInfo.eventName} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Bet Status</p>
                      <Tooltips info={betHistoryInfo.betStatus} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Rejection code</p>
                      <Tooltips info={betHistoryInfo.rejection} />
                    </div>
                  </th>
                  <th
                    onClick={() => {
                      this.setState({ asceending: !this.state.asceending });
                    }}
                  >
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Placed On</p>
                      <Tooltips info={betHistoryInfo.Placed_On} />
                      <span
                        className='material-icons md-18'
                        data-icon={
                          this.state.asceending === true ? "south" : "north"
                        }
                      ></span>
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Settled On</p>
                      <Tooltips info={betHistoryInfo.Settled_On} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Betfold</p>
                      <Tooltips info={betHistoryInfo.Betfold} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Bet Type</p>
                      <Tooltips info={betHistoryInfo.BetType} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Stake&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Stake} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Payout&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Payout} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Potential Payout&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Potential_Payout} />
                    </div>
                  </th>
                  {showPermission ? <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Stake Tax&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Stake_Tax} />
                    </div>
                  </th> : ''}
                  {showPermission ? <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Stake After Tax&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Stake_AfterTax} />
                    </div>
                  </th> : ''}
                  {showPermission ? <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Stake Tax Amount&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Stake_TaxAmount} />
                    </div>
                  </th> : ''}
                  {showPermission ? <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Winnings Tax&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Winnings_Tax} />
                    </div>
                  </th> : ''}
                  {showPermission ? <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Winnings Tax Amount&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Winnings_TaxAmount} />
                    </div>
                  </th> : ''}
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Total Payout&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Total_Payout} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Total Payout With Bonus&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Total_PayoutWithBonus} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Bonus Max Win Amounts&nbsp;({Currency()})</p>
                      <Tooltips info={betHistoryInfo.Bonus_MaxWinAmount} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Bonus Max Win Percentage</p>
                      <Tooltips info={betHistoryInfo.Bonus_MaxWinPercentage} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Place Bet Type</p>
                      <Tooltips info={betHistoryInfo.Place_BetType} />
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>Audit</p>
                      <Tooltips info={betHistoryInfo.Audit} />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props?.data && Array.isArray(this.props?.data) && this.props?.data.length > 0 ? (
                  this.dateSorting().map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span
                            className='link_style pointer'
                            onClick={() => this.openDialogHandler(item.betId, formatMoney(item.potentialPayout))}
                          >
                            {item.betId}
                          </span>
                        </td>
                        {/* <td>{item.bookingCode || '-'}</td> */}
                        <td>{item.shortBetId}</td>
                        <td>{item.fixtureName}</td>
                        <td>{item.betStatus}</td>
                        <td>{item.rejectionCode}</td>
                        <td>{getDateTimeFormate(item.placedDate)}</td>
                        {/* <td>{getDateTimeFormate(item.transactionSettledDate)}</td> */}
                        {/* <td>{item.bets.length} </td> */}
                        <td>
                          {item.settledDate
                            ? getDateTimeFormate(item.settledDate)
                            : "-"}
                        </td>
                        <td>{item.betFold}</td>
                        <td>{item.betType}</td>
                        <td className='text-right'>
                          {formatMoney(item.stake)}{" "}
                        </td>
                        <td className='text-right'>
                          {formatMoney(item.payout)}{" "}
                        </td>
                        <td className='text-right'>
                          {formatMoney(item.potentialPayout)}{" "}
                        </td>
                        {showPermission ? <td>{item.stakeTax}</td> : ''}
                        {showPermission ? <td>{formatMoney(item.stakeAfterTax)}</td> : ''}
                        {showPermission ? <td>{formatMoney(item.stakeTaxAmount)}</td> : ''}
                        {showPermission ? <td>{item.winningsTax}</td> : ''}
                        {showPermission ? <td>{formatMoney(item.winningsTaxAmount)}</td> : ''}
                        <td>{formatMoney(item.totalPayout)}</td>
                        <td>{formatMoney(item.totalPayoutWithBonus)}</td>
                        <td>{formatMoney(item.bonusMaxWinAmount)}</td>
                        <td>{item.bonusMaxWinPercentage}</td>
                        <td>{item.placeBetType}</td>
                        <td>
                          <button
                            className='CMS-btn CMS-btnSecondary active CMS-btnSmall btnShow '
                            type='button'
                            onClick={() => this.callEventDetails(item.betId)}
                          >
                            Bet Audit
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="no_data_screen">
                    <td colSpan={22}>No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    betIdRecords: state.betHistorySliceReducer.betIdRecords,
    betAudit: state.playerBetHistorySlice.betAudit,
    stakeTaxPermit: state.dashboard.stakeTaxPermission,
    winTaxPermit: state.dashboard.winTaxPermission
  };
}
export default connect(mapStateToProps)(BettingHistoryTable);
