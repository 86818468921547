import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BetHistoryTable from "./betHistoryTable";
import {
  getBetHistorytableList,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setFormData,
  setDocsData,
  setReportDocs,
  setReportsLoader,
  getAllCasinoDropdowns,
  setCasinoData,
  setBetPayloadData,
  getVirtualsProviderNameDropdown
} from "./betHistorySlice";
import {
  getDateTimeFormate,
  getDecimalNumber,
  formatMoney,
  cleanObject,
  getUTCDate,
  sortingFilter,
  roundDecimalnum,
  regexType,
  Currency,
  getBrowserDate,
  MomentUTCDateTimeToday,
  getFormattedDate,
  MomentUTCDateTimeEnd,
  extractedDate,
  getFuturedDate,
} from "../../sharedfiles/helper";
import { fetchSportsList } from "../createbonusnew/createbonusnewslice";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import Select from "../Common/common_inputs/Select_DDL";
import Datepicker from "../Common/common_inputs/DatePicker";
import { BettingHistoryData } from "../Common/data/mapData";
import {
  BetStatus,
  Betfold,
  BetType,
  mainTableHeaders,
  betSortables,
  CasinoType,
  GameType,
  userRole,
  sportGameType,
  SportsBetType,
  sportsInBetHistory,
} from "../Common/data/BettingHistory/BettinghistoryData";
import Sklton from "../Common/skelton/Skelton";
import { Betting, Bonusing, casinoDropdown } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import MultiSelectDDL from "../../sharedfiles/MultiSelectDDL";
import { BONUS_CONSTANTS } from "../../sharedfiles/Constants";
import AmountRangeIp from "../Common/common_inputs/AmountRangeIp";
import MultipleSelectDDL from "../Common/common_inputs/MultipleSelectDDL";

const sortState = {
  "Bet Status": false,
  "Rejection Code": false,
  "Age Of Account": false,
  "Customer Id": false,
  "Stake Factor": false,
  "Betfold": false,
  "Currency": false,
  "Odds": false,
  "Stake": false,
  "Bonus Amount": false,
  "Bonus Type": false,
  "Potential Payout": false,
  "Payout": false,
  "Placed On": false,
  "Settled On": false,
  key: "",
  direction: "",
  keyType: ""
}

class BetHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      betType: "",
      betId: "",
      sport: "",
      brand: "",
      description: "",
      eventName: "",
      stake: "",
      totalStake: "",
      win: "",
      customer: "",
      placedOn: "",
      settledOn: "",
      settled: "",
      rejectionCode: "",
      walletID: "",
      currency: "",
      customerId: "",
      rejected: "",
      voided: "",
      subcategory: "",
      placedBefore: "",
      placedAfter: "",
      resulted: "",
      market: "",
      data: [],
      country: "",
      document: "",
      sendData: false,
      placedBeforeOrg: "",
      statusErrorMsg: false,
      typeErrorMsg: false,
      placedAfterOrg: "",
      payloadData: {},
      itemsperpage: "",
      csvData: [],
      pdfBody: [],
      sportsSelected: [],
      clearAll: false,
      bettingType: "",
      gamingType: [],
      settledBefore: "",
      settledAfter: "",
      dateError: "",
      sdateError: "",
      view: false,
      reportDocs: {
        pdf: [],
        csv: []
      },
      sortFields: {
        ...sortState
      },
      headers: mainTableHeaders,
      betStatus: [],
      checkValues: [],
      sportId: [],
      sportsSelected: [],
      clearAll: false,
      stakeMinAmnt: "",
      stakeMaxAmnt: "",
      payoutMinAmnt: "",
      payoutMaxAmnt: "",
      stakeRangeErr: "",
      payoutRangeErr: "",
      shouldReset: false,
      casinoClearAll: false,
      CATEGORY: [],
      GAME_NAME: [],
      PROVIDER_NAME: [],
      SUB_PROVIDER_NAME: [],
      role: "",
      userName: '',
      agentName: '',
      virtualProviders: [],
      resetVirtualProviders: false,
      gamingTypeReset: false,
      bookingCode: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.brandHandler = this.brandHandler.bind(this);
    this.myDocument = this.myDocument.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.callApi = this.callApi.bind(this);
    this.handleChangeGaming = this.handleChangeGaming.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.setClear = this.setClear.bind(this);
    this.setDateHandler = this.setDateHandler.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.handleChangeBetting = this.handleChangeBetting.bind(this);
  }


  setDateHandler(value, name) {
    this.setState({ [name]: value });
    let payload = this.playerserachdata();
    if (name === "placedBefore") {
      payload["placedBefore"] = value;
      const StartDateError = this.state.placedAfter === "" ? "Placed After is required" : ""
      this.setState({
        dateError:
          StartDateError,
      });
      this.formDataToSliceSetter("", "", { [name]: value, "dateError": StartDateError });
    } else {
      payload["placedAfter"] = value;
      this.setState({ dateError: "" });
      this.formDataToSliceSetter("", "", { [name]: value, "dateError": "" });
    }
  }

  setDateSettleHandle(svalue, sname) {
    this.setState({ [sname]: svalue });
    let payload = this.playerserachdata();
    if (sname === "settledBefore") {
      payload["settledBefore"] = svalue;
      const SettleDateError = this.state.settledAfter === "" ? "Settled After is required" : ""
      this.setState({
        sdateError:
          SettleDateError,
      });
      this.formDataToSliceSetter("", "", { [sname]: svalue, "sdateError": SettleDateError });
    } else {
      payload["settledAfter"] = svalue;
      this.setState({ sdateError: "" });
      this.formDataToSliceSetter("", "", { [sname]: svalue, "sdateError": "" });
    }
  }
  emptyState() {
    this.props.dispatch(setCasinoData({}))
    this.setState({
      id: "",
      betType: "",
      betId: "",
      sport: "",
      brand: "",
      description: "",
      eventName: "",
      event: "",
      stake: "",
      totalStake: "",
      win: "",
      customer: "",
      placedOn: "",
      settledOn: "",
      settled: "",
      rejectionCode: "",
      walletID: "",
      currency: "",
      customerId: "",
      rejected: "",
      voided: "",
      subcategory: "",
      placedAfter: '',
      placedBefore: '',
      settledAfter: '',
      settledBefore: '',
      resulted: "",
      market: "",
      data: [],
      country: "",
      betStatus: [],
      checkValues: [],
      conditon: false,
      sendData: false,
      statusErrorMsg: false,
      typeErrorMsg: false,
      placedBeforeOrg: "",
      placedAfterOrg: "",
      // payloadData: {},
      itemsperpage: "",
      csvData: [],
      pdfBody: [],
      sportsSelected: [],
      clearAll: true,
      bettingType: "",
      gamingType: ["SPORTS", "VIRTUALS"],
      dateError: "",
      sdateError: "",
      stakeMinAmnt: "",
      stakeMaxAmnt: "",
      payoutMinAmnt: "",
      payoutMaxAmnt: "",
      stakeRangeErr: "",
      payoutRangeErr: "",
      shouldReset: true,
      inputCasinoValue: [],
      role: "",
      userName: '',
      CATEGORY: [],
      GAME_NAME: [],
      PROVIDER_NAME: [],
      SUB_PROVIDER_NAME: [],
      agentName: "",
      resetVirtualProviders: true,
      gamingTypeReset: true,
    });
    this.setState({ data: [] });
  }
  resetButton() {
    this.emptyState();
    this.props.dispatch(setCasinoData([]))
    // this.props.dispatch(setBetPayloadData({}))
    this.setState({
      // betPayloadData: {},
      reportDocs: {
        pdf: [],
        csv: []
      },
      sortFields: {
        ...sortState
      }
    });
    this.props.dispatch(setPaginationFirstValue(1));
    this.props.dispatch(setPaginationSecondValue(25));
    this.props.dispatch(setRecordsShow([1, 25]));
    // this.callApi(1, 25, {});
    this.props.dispatch(getBetHistorytableList(0, 25, { gameType: ["SPORTS", "VIRTUALS"] }))
    this.props.dispatch(setIsVisible(false));
    this.props.dispatch(setFormData({}));
    this.props.dispatch(setBetPayloadData({ gameType: ["SPORTS", "VIRTUALS"] }))
  }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ conditon: false });
    this.formDataToSliceSetter(event.target.name, event.target.value);
  };

  handleChangeGaming = (event) => {
    // const { name, value } = event.target;
    // const valueArray = Array.isArray(value) ? value : [value];

    this.setState(prevState => ({
      ...prevState,
      gamingType: event,
      condition: false
    }));

    this.formDataToSliceSetter("gamingType", event);

    if (event === "CASINO") {
      this.props.dispatch(getAllCasinoDropdowns());
    }
  };
  handleChangeBetting = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ conditon: false });
    this.formDataToSliceSetter(event.target.name, event.target.value);
  };


  callApi(pageNumber, itemsPerPage, data = this.props.betPayloadData, activeAll) {

    const placedAfter = extractedDate(this.state.placedAfter)
    const placedBefore = extractedDate(this.state.placedBefore)
    const settleAfter = extractedDate(this.state.settledAfter)
    const settledBefore = extractedDate(this.state.settledBefore)

    !activeAll && this.props.dispatch(setIsVisible(false));
    const { ...filterData } = data;
    const payload = {
      placedAfter: this.state.placedAfter ? MomentUTCDateTimeToday(this.state.placedAfter) : '',
      placedBefore: this.state.placedBefore ? (placedAfter === placedBefore ? MomentUTCDateTimeEnd(this.state.placedBefore) : MomentUTCDateTimeEnd(this.state.placedBefore)) : "",
      settledAfter: this.state.settledAfter ? MomentUTCDateTimeToday(this.state.settledAfter) : '',
      settledBefore: this.state.settledBefore ? (settleAfter === settledBefore ? MomentUTCDateTimeEnd(this.state.settledBefore) : MomentUTCDateTimeEnd(this.state.settledBefore)) : '',
      gameType: ["SPORTS", "VIRTUALS"],
      ...data,
    }
    this.props.dispatch(
      getBetHistorytableList(
        pageNumber - 1,
        itemsPerPage,
        payload,
        activeAll
      )
    );
  }
  playerserachdata() {
    const placedAfter = extractedDate(this.state.placedAfter)
    const placedBefore = extractedDate(this.state.placedBefore)
    const settleAfter = extractedDate(this.state.settledAfter)
    const settledBefore = extractedDate(this.state.settledBefore)

    const payload = {
      gameType: this.state.gamingType,
      betType: this.state.bettingType,
      betId: this.state.betId,
      betSize: this.state.betType,
      betStatus: this.state.betStatus,
      placedAfter: this.state.placedAfter ? MomentUTCDateTimeToday(this.state.placedAfter) : '',
      placedBefore: this.state.placedBefore ? (placedAfter === placedBefore ? MomentUTCDateTimeEnd(this.state.placedBefore) : MomentUTCDateTimeEnd(this.state.placedBefore)) : '',
      settledAfter: this.state.settledAfter ? MomentUTCDateTimeToday(this.state.settledAfter) : '',
      settledBefore: this.state.settledBefore ? (settleAfter === settledBefore ? MomentUTCDateTimeEnd(this.state.settledBefore) : MomentUTCDateTimeEnd(this.state.settledBefore)) : '',
      sport: this.state.sportsSelected,
      applicableSports: this.state.sportsSelected,
      userId: this.state.customerId,
      minStake: this.state.stakeMinAmnt !== "" ? this.state.stakeMinAmnt * 100 : this.state.stakeMinAmnt,
      maxStake: this.state.stakeMaxAmnt !== "" ? this.state.stakeMaxAmnt * 100 : this.state.stakeMaxAmnt,
      minPotentialPayout: this.state.payoutMinAmnt !== "" ? this.state.payoutMinAmnt * 100 : this.state.payoutMinAmnt,
      maxPotentialPayout: this.state.payoutMaxAmnt !== "" ? this.state.payoutMaxAmnt * 100 : this.state.payoutMaxAmnt,
      category: this.state.CATEGORY,
      gameName: this.state.GAME_NAME,
      providerName: this.state.PROVIDER_NAME,
      subProviderName: this.state.SUB_PROVIDER_NAME,
      role: this.state.role,
      userName: this.state.userName,
      parentAgentUserName: this.state.agentName,
      providerName: this.state.virtualProviders,
      // bookingCode: this.state.bookingCode
    };
    if (["CASINO", " "].includes(this.state.gamingType)) {
      delete payload["sport"];
      this.setState({ sport: "" })
      this.formDataToSliceSetter("sport", "")
    };
    return payload;
  }

  onSubmit(e) {
    e.preventDefault();
    const data = cleanObject(this.playerserachdata());
    if (Object.keys(data).length > 0) {
      if (!this.state.dateError && !this.state.sdateError) {
        this.props.dispatch(setIsVisible(false));
        // this.setState({ payloadData: data });
        this.props.dispatch(setBetPayloadData(data))
        this.callApi(1, 25, data);
        this.props.dispatch(setPaginationFirstValue(1))
        this.props.dispatch(setPaginationSecondValue(25))
        this.props.dispatch(setRecordsShow([1, 25]))
        this.setState({
          sendData: true,
        });
      }
    }
  }

  myDocument(e) {
    this.setState({ document: e.target.value });
  }

  exportPDF(totalRecords) {
    const title = "Sport Bet History Report";

    const styles = {
      10: {
        halign: "right",
        valign: "middle",
      },
      12: {
        halign: "right",
        valign: "middle",
      },
    };
    const pdfData = totalRecords ? this.props.reportDocs?.pdf : this.state.pdfBody
    const size = "A2"
    exportPDFC(title, [this.state.headers], pdfData, styles, "", size);
  }

  brandHandler = (e) => {
    this.setState({ brand: e.target.value });
    this.setState({ open: false });
  };

  handleClickDropdown = () => {
    this.setState({ open: !this.state.open });
  };
  updateTableToDocs(allRecords, key = "", sortType = "", keyType = "", headers) {
    const showPermission = this.props.stakeTaxPermit && this.props.winTaxPermit
    const isGameworx = this.state.view
    let props = this.props;
    let reportsData = props.reportsData;
    let getData = props.getData;
    let inputData = [];
    if (allRecords) {
      inputData = reportsData && Array.isArray(reportsData) && reportsData.length > 0 ? reportsData : [];
    } else {
      inputData = getData && Array.isArray(getData) ? getData : [];
    }
    const data =
      sortingFilter(inputData, { key, sortType, keyType }).map((elt) => {
        let mainHeaderStack = [
          elt.betId,
          // elt.bookingCode || "-",
          elt.shortBetId,
          elt.betStatus,
          elt.rejectionCode,
          elt.ageOfAccount,
          elt.userId,
          elt.role,
          elt.userName,
          elt.firstName,
          elt.lastName,
          elt.surName,
          elt.phone,
          getDecimalNumber(elt.stakeFactor),
          elt.fixtureName ? elt.fixtureName : "Multibet",
          elt.betFold,
          elt.betType,
          elt.currencyCode,
          elt?.odds ? elt.odds : "Multibet",
          isGameworx ? roundDecimalnum(elt.stake) : formatMoney(elt.stake),
          elt.placeBetType,
          elt.fixtureStatus,
          // isGameworx ? roundDecimalnum(elt.stake_real) : "NaN" ? "" : formatMoney(elt.stake_real),
          // isGameworx ? roundDecimalnum(elt.stake_bonus) : "NaN" ? "" : formatMoney(elt.stake_bonus),
          elt.gameType === 'CASINO' ? "-" : elt.gameType,
          formatMoney(elt.parlayWinning),
          elt.bonusPayout,
          isGameworx ? roundDecimalnum(elt.potentialPayout) : formatMoney(elt.potentialPayout),
          isGameworx ? roundDecimalnum(elt.payout) : formatMoney(elt.payout),
          elt.placedDate ? getDateTimeFormate(elt.placedDate) : "-",
          elt.settledDate ? getDateTimeFormate(elt.settledDate) : "-",
          elt.stakeTax,
          formatMoney(elt.stakeAfterTax),
          formatMoney(elt.stakeTaxAmount),
          elt.winningsTax,
          formatMoney(elt.winningsTaxAmount),
          formatMoney(elt.totalPayout),
          formatMoney(elt.totalPayoutWithBonus),
          formatMoney(elt.bonusMaxWinAmount),
          elt.bonusMaxWinPercentage,
          elt.subBetType ? elt.subBetType : "-",
          elt.parentAgentUserId || "-",
          elt.parentAgentUserName || "-",
          elt.providerName || "-",
          // elt.numOfCombinations ? elt.numOfCombinations.toString() : '-',
          // elt.wonCount + '/' + elt.lostCount + '/' + elt.voidCount,
          // elt.groups ? elt.groups.toString() : '-'
        ]
        const casinoHeader = [
          elt.providerName,
          elt.subProviderName,
          elt.gameName,
          elt.category,
          // elt.gameCode,
        ];

        if (this.state.gamingType === "CASINO") {
          mainHeaderStack = [...mainHeaderStack, ...casinoHeader]
        }
        return mainHeaderStack;
      })
    const filterData = data.map((record) => record.filter((v, ind) => ind !== 31 && ind !== 32 && ind !== 28 && ind !== 29 && ind !== 30))
    const opData = showPermission ? data : filterData;
    const csvData = opData && Array.isArray(opData) && opData.length > 0 ? [headers, ...opData] : [headers, []];
    this.setState({ headers: headers });
    if (allRecords) {
      let packet = {
        pdf: opData,
        csv: csvData
      }
      packet = JSON.parse(JSON.stringify(packet));
      this.setState({
        reportDocs: packet
      })
      this.props.dispatch(setReportDocs(packet));
    } else {

      this.setState({ pdfBody: JSON.parse(JSON.stringify(opData)) });
      this.setState({ csvData: JSON.parse(JSON.stringify(csvData)) });
      this.props.dispatch(setDocsData(JSON.parse(JSON.stringify(csvData))));
    }

  }
  searchResults(e) {
    if (e.keyCode === 13) {
      this.onSubmit(e);
    }
  }

  downloadAllRecords() {
    this.updateTableToDocs(true, "", "", "", mainTableHeaders);
  }
  reportsCaller(pages) {
    this.callApi(1, pages, this.props.betPayloadData, true);
  }
  getDefaultDateRange = () => {
    const startDate = getFormattedDate(1);
    const endDate = new Date();
    return { startDate, endDate };
  }
  componentDidMount() {
    this.props.dispatch(getVirtualsProviderNameDropdown())
    const { startDate, endDate } = this.getDefaultDateRange();
    document.addEventListener("keydown", this.searchResults);
    if (this.props.location.state !== undefined) {
      this.setState({ betId: this.props.location.state.id })
      this.props.dispatch(
        getBetHistorytableList(this.props.paginationFirstValue - 1,
          this.props.paginationSecondValue, {
          betId: this.props.location.state.id
        })
      )
    }
    const dataExist = Object.keys(this.props?.userData).length > 0
    const systemPayload = {
      placedAfter: MomentUTCDateTimeToday(endDate),
      placedBefore: MomentUTCDateTimeEnd(getFuturedDate(1)),
      betPayloadData: this.props.betPayloadData,
      gameType: ["SPORTS", "VIRTUALS"],

    };
    if (!dataExist) {
      this.setState({ placedBefore: getFuturedDate(1), placedAfter: new Date(), gamingType: ["SPORTS", "VIRTUALS"] })
      this.props.dispatch(setFormData({
        ...this.props.formData,
        placedAfter: new Date(),
        placedBefore: getFuturedDate(1),
        gamingType: ["SPORTS", "VIRTUALS"]
      }));
      this.callApi(1, 25, systemPayload);
      this.props.dispatch(fetchSportsList());
    } else {
      const csvDataExist = this.props?.docsData && Array.isArray(this.props.docsData) && this.props.docsData.length > 0
      let pdfData = [];
      if (csvDataExist) {
        pdfData = this.props?.docsData.slice(1, this.props?.docsData?.length);
      }
      this.setState({
        csvData: this.props.docsData,
        pdfBody: pdfData,
        reportDocs: this.props.reportsData
      })
    }
    if (localStorage.getItem("isExist") === "GAMEWORX_PERMISSION") {
      this.setState({ view: true })
    } else if (localStorage.getItem("isExist") === "notExist") {
      this.setState({ view: false })
    }
    this.stateSetter();
    const { stakeTaxPermit, winTaxPermit } = this.props;
    const showPermissions = stakeTaxPermit && winTaxPermit;


    if (!showPermissions) {
      let v = []
      if (this.state.gamingType !== "CASINO") {
        v = mainTableHeaders.filter((name, index) => {
          return name != "Stake Tax ₦" && name != "Stake After Tax ₦" && name != "Stake Tax Amount  ₦" && name != "Winnings Tax ₦" && name != "Winnings Tax Amount ₦" && name != "Aggregator" && name != "Provider" && name != "Game Name" && name != "Category"
        });
      }
      else {
        v = mainTableHeaders.filter((name, index) => name != "Stake Tax ₦" && name != "Stake After Tax ₦" && name != "Stake Tax Amount  ₦" && name != "Winnings Tax ₦" && name != "Winnings Tax Amount ₦");
      }
      this.setState({ headers: v });
      this.updateTableToDocs(false, "", "", "", v);
    } else {
      if (this.state.gamingType !== "CASINO") {
        const v = mainTableHeaders.filter((name, index) => name != "Aggregator" && name != "Provider" && name != "Game Name" && name != "Category");
        this.updateTableToDocs(false, "", "", "", v);
      }
      else {
        this.updateTableToDocs(false, "", "", "", mainTableHeaders);
      }
    }
  }
  componentDidUpdate(prevProps) {
    // if (prevProps.getData !== this.props.getData) {
    //   this.updateTableToDocs(false, "", "", "", mainTableHeaders);
    // this.reportsCaller(this.props.userData.totalRecords)
    // }
    if (prevProps.formData !== this.props.formData) {
      this.stateSetter();
    }
    if (prevProps.reportsData !== this.props.reportsData) {
      this.downloadAllRecords();
    }
    if (prevProps.stakeTaxPermit !== this.props.stakeTaxPermit || prevProps.winTaxPermit != this.props.winTaxPermit || prevProps.getData !== this.props.getData) {
      const { stakeTaxPermit, winTaxPermit } = this.props;
      const showPermissions = stakeTaxPermit && winTaxPermit;
      if (!showPermissions) {

        let v = []
        if (this.state.gamingType !== "CASINO") {
          v = mainTableHeaders.filter((name, index) => name != "Stake Tax ₦" && name != "Stake After Tax ₦" && name != "Stake Tax Amount  ₦" && name != "Winnings Tax ₦" && name != "Winnings Tax Amount ₦" && name != "Aggregator" && name != "Provider" && name != "Game Name" && name != "Category"
          );
        }
        else {
          v = mainTableHeaders.filter((name, index) => name != "Stake Tax ₦" && name != "Stake After Tax ₦" && name != "Stake Tax Amount  ₦" && name != "Winnings Tax ₦" && name != "Winnings Tax Amount ₦");
        }
        this.setState({ headers: v });
        this.updateTableToDocs(false, "", "", "", v);
      } else {
        if (this.state.gamingType !== "CASINO") {
          const v = mainTableHeaders.filter((name, index) => name != "Aggregator" && name != "Provider" && name != "Game Name" && name != "Category");
          this.updateTableToDocs(false, "", "", "", v);
        }
        else {
          this.updateTableToDocs(false, "", "", "", mainTableHeaders);
        }

      }
    }

  }
  setClear() {
    this.setState({ clearAll: false });
  }
  setCasinoClear() {
    this.setState({ casinoClearAll: false })
  }

  stateSetter() {
    const isFormDataExist = Object.keys(this.props.formData).length > 0
    if (isFormDataExist) {
      Object.keys(this.props.formData).map(key => this.setState({ [key]: this.props.formData[key] }))
    }
  }

  formDataToSliceSetter(key, val, multiEntries) {
    const newEntry = { ...this.props.formData, [key]: val }
    const newPayload = multiEntries ? { ...this.props.formData, ...multiEntries } : newEntry
    // const newPayload = {...this.props.formData, [key]: val}
    this.props.dispatch(setFormData(newPayload));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.searchResults, false);
  }


  handleSortFields(item, key) {
    const intializing = {
      ...sortState
    }
    const direction = !this.state.sortFields[key] ? "ASCENDING" : "DESCENDING";

    this.setState({
      sortFields: {
        ...intializing,
        [key]: !this.state.sortFields[key],
        key: item.key,
        direction: direction,
        keyType: item.type,
      }
    });
    const { stakeTaxPermit, winTaxPermit } = this.props;
    const showPermissions = stakeTaxPermit && winTaxPermit;
    if (!showPermissions) {
      let v = []
      if (this.state.gamingType !== "CASINO") {
        v = mainTableHeaders.filter((name, index) => {
          return name != "Stake Tax ₦" && name != "Stake After Tax ₦" && name != "Stake Tax Amount  ₦" && name != "Winnings Tax ₦" && name != "Winnings Tax Amount ₦" && name != "Aggregator" && name != "Provider" && name != "Game Name" && name != "Category"
        });
      }
      else {
        v = mainTableHeaders.filter((name, index) => name != "Stake Tax ₦" && name != "Stake After Tax ₦" && name != "Stake Tax Amount  ₦" && name != "Winnings Tax ₦" && name != "Winnings Tax Amount ₦");
      }
      this.setState({ headers: v });
      this.updateTableToDocs(false, item.key, direction, item.type, v);
    } else {
      if (this.state.gamingType !== "CASINO") {
        const v = mainTableHeaders.filter((name, index) => name != "Aggregator" && name != "Provider" && name != "Game Name" && name != "Category");
        this.updateTableToDocs(false, item.key, direction, item.type, v);
      }
      else {
        this.updateTableToDocs(false, item.key, direction, item.type, mainTableHeaders);
      }
    }
  }

  handleSelectedValues(selected) {
    this.setState({
      betStatus: selected,
      shouldReset: false
    });
    this.formDataToSliceSetter(null, null, {
      betStatus: selected,
      shouldReset: false
    })

  }

  setSelected(sport) {
    this.setState({ sportsSelected: sport });
    this.formDataToSliceSetter("sportsSelected", sport)
  }

  setIds(value) {
    this.setState({ sportId: value });
    this.formDataToSliceSetter("sportId", value);
  }

  stakeRangeHandle(e) {
    const { name, value } = e.target;

    const sanitizedValue = value.replace(/[^0-9.]/g, '');

    if (name === "stakeMinAmnt" || name === "stakeMaxAmnt") {
      this.setState((prevState) => ({
        [name]: sanitizedValue,
        stakeRangeErr:
          (name === "stakeMinAmnt" && prevState.stakeMaxAmnt !== '' && parseFloat(sanitizedValue) > parseFloat(prevState.stakeMaxAmnt)) ||
            (name === "stakeMaxAmnt" && prevState.stakeMinAmnt !== '' && parseFloat(prevState.stakeMinAmnt) > parseFloat(sanitizedValue))
            ? 'Max amount should not be less than min amount'
            : '',
      }));
    }

    this.formDataToSliceSetter(name, sanitizedValue);
  }


  payoutRangeHandle(e) {
    const { name, value } = e.target;

    const sanitizedValue = value.replace(/[^0-9.]/g, '');

    if (name === "payoutMinAmnt" || name === "payoutMaxAmnt") {
      this.setState((prevState) => ({
        [name]: sanitizedValue,
        payoutRangeErr:
          (name === "payoutMinAmnt" && prevState.payoutMaxAmnt !== '' && parseFloat(sanitizedValue) > parseFloat(prevState.payoutMaxAmnt)) ||
            (name === "payoutMaxAmnt" && prevState.payoutMinAmnt !== '' && parseFloat(prevState.payoutMinAmnt) > parseFloat(sanitizedValue))
            ? 'Max amount should not be less than min amount'
            : '',
      }));
    }

    this.formDataToSliceSetter(name, sanitizedValue);
  }

  // handleCasinoChange(event) {
  //   const { name, value } = event.target
  //   const newData = this.props.casinoData || [];
  //   this.props.dispatch(setCasinoData([...newData, { [name]: value }]))
  // }

  getCategory = (cat) => CasinoType.find(e => e.value === cat);
  getInputValue = (key) => this.props?.casinoData.find((element) => element[key]);

  setCasinoSelected(value, key) {
    this.setState({ [key]: value });
  }

  handleRoleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.formDataToSliceSetter(event.target.name, event.target.value);
  }
  handleUserChange(event) {
    const { name, value } = event.target
    this.setState({ userName: value })
    this.formDataToSliceSetter('userName', value)
  }
  handleAgent = (e) => {
    const { name, value } = e.target
    this.setState({ agentName: value })
    this.formDataToSliceSetter("agentName", value)
  }
  handleSelectTridentProviders = (value) => {
    this.setState({ virtualProviders: value });
    this.formDataToSliceSetter("virtualProviders", value)
  }

  handleBookingCode = (e) => {
    const { name, value } = e.target
    this.setState({ bookingCode: value })
  }
  render() {
    const sportsData = this.state.gamingType.length > 0
      ? (this.state.gamingType.includes("SPORTS") ? this.props.sportsData : sportsInBetHistory)
      : this.props.sportsData;
    return (
      <>
        <div className='CMS-tab-panel active' id='CMS-betting'>
          <div className='CMS-tabContent'>
            <div className='CMS-box CMS-box-content'>
              <div className='row'>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <div className='CMS-formGroup'>
                    <div className='CMS-formLabel'>
                      {"Game Type"}
                      &nbsp;&nbsp;
                      <Tooltips info={Betting.Game_Type} />
                    </div>
                    <MultipleSelectDDL
                      options={sportGameType}
                      onSelect={(value) => this.handleChangeGaming(value)}
                      shouldReset={this.state.gamingTypeReset}
                      initialSelected={this.state.gamingType || []}
                    />
                  </div>
                  {/* <Select
                    title={"Game Type"}
                    name={"gamingType"}
                    ids={"GameType"}
                    value={this.state.gamingType}
                    setValue={(val) => this.handleChangeGaming(val)}
                    options={sportGameType}
                    errorMsg={
                      this.state.typeErrorMsg
                        ? "Please select gaming Type"
                        : ""
                    }
                    onSubmit={(e) => this.onSubmit(e)}
                    info={Betting.Game_Type}
                    noSelectOpt
                  /> */}
                </div>
                {
                  <div className='col-md-4 col-lg-3 col-xl-2'>
                    <Select
                      title={"Bet Type"}
                      name={"bettingType"}
                      ids={"BetType"}
                      value={this.state.bettingType}
                      setValue={(val) => this.handleChangeBetting(val)}
                      options={SportsBetType}
                      errorMsg={
                        this.state.typeErrorMsg
                          ? "Please select betting Type"
                          : ""
                      }
                      onSubmit={(e) => this.onSubmit(e)}
                      info={Betting.Bet_Type}
                    />
                  </div>}
                {BettingHistoryData.map((item, ind) => {
                  const values = [this.state.customerId, this.state.betId];
                  return (
                    <React.Fragment key={item.ids}>
                      <div className='col-md-4 col-lg-3 col-xl-2' key={item.id}>
                        <Input
                          title={item.title}
                          name={item.name}
                          value={values[ind]}
                          placeholder={item.placeholder}
                          setValue={(e) => this.handleChange(e)}
                          regex={item.regex}
                          ids={item.ids}
                          info={item.info}
                          show={true}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
                {/* <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Input
                    title={"Booking Code"}
                    name={"bookingcode"}
                    value={this.state.bookingCode}
                    placeholder={'Booking Code'}
                    setValue={(e) => this.handleBookingCode(e)}
                    ids={"bookingcode"}
                    info={"Filter out Booking Code"}
                    show={true}
                  />
                </div> */}
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Input
                    title="User Name"
                    name="userName"
                    value={this.state.userName}
                    placeholder="Username"
                    setValue={(e) => this.handleUserChange(e)}
                    id="username"
                    info={Betting.userName}
                    show={true}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <div className='CMS-formGroup'>
                    <div className='CMS-formLabel'>
                      {"Bet Status"}
                      &nbsp;&nbsp;
                      <Tooltips info={Betting.Bet_Status} />
                    </div>
                    <MultipleSelectDDL
                      multiName={["betStatus"]}
                      options={BetStatus}
                      onSelect={(value) => this.handleSelectedValues(value)}
                      shouldReset={this.state.shouldReset}
                      initialSelected={this?.props?.formData?.betStatus || []}
                    />
                  </div>
                </div>

                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Datepicker
                    title={"Placed After"}
                    name={"placedAfter"}
                    value={this.state.placedAfter}
                    setValue={(d, n) => this.setDateHandler(d, n)}
                    // minDate={this.state.placedBefore}
                    maxDate={this.state.placedBefore || new Date()}
                    errorMsg={this.state.dateError}
                    ids={"PlacedAfter"}
                    info={Betting.dates}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Datepicker
                    title={"Placed Before"}
                    name={"placedBefore"}
                    value={this.state.placedBefore}
                    setValue={(d, n) => this.setDateHandler(d, n)}
                    minDate={this.state.placedAfter}
                    maxDate={new Date()}
                    ids={"PlacedBefore"}
                    info={Betting.dates}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Select
                    title={"Betfold"}
                    name={"betType"}
                    ids={"betFold"}
                    value={this.state.betType}
                    setValue={(val) => this.handleChangeBetting(val)}
                    options={Betfold}
                    onSubmit={(e) => this.onSubmit(e)}
                    info={Betting.Bet_fold}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <AmountRangeIp
                    title={"Stake Range"}
                    amountMin={this.state.stakeMinAmnt}
                    amountMax={this.state.stakeMaxAmnt}
                    rangeHandle={(e) => this.stakeRangeHandle(e)}
                    rangeErr={this.state.stakeRangeErr}
                    minName={"stakeMinAmnt"}
                    maxName={"stakeMaxAmnt"}
                    info={Betting.stakeRange}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <AmountRangeIp
                    title={"Potential Payout Range"}
                    amountMin={this.state.payoutMinAmnt}
                    amountMax={this.state.payoutMaxAmnt}
                    rangeHandle={(e) => this.payoutRangeHandle(e)}
                    rangeErr={this.state.payoutRangeErr}
                    minName={"payoutMinAmnt"}
                    maxName={"payoutMaxAmnt"}
                    info={Betting.payoutRange}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Datepicker
                    title={"Settled After"}
                    name={"settledAfter"}
                    value={this.state.settledAfter}
                    setValue={(date, settlename) => this.setDateSettleHandle(date, settlename)}
                    // minDate={this.state.settledBefore}
                    maxDate={this.state.settledBefore || new Date()}
                    errorMsg={this.state.sdateError}
                    ids={"settledAfter"}
                    info={Betting.settledDates}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Datepicker
                    title={"Settled Before"}
                    name={"settledBefore"}
                    value={this.state.settledBefore}
                    setValue={(date, settlename) => this.setDateSettleHandle(date, settlename)}
                    minDate={this.state.settledAfter}
                    maxDate={new Date()}
                    ids={"settledBefore"}
                    info={Betting.settledDates}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Select
                    title={"User Role"}
                    name={"role"}
                    ids={"userRole"}
                    value={this.state.role}
                    setValue={(val) => this.handleRoleChange(val)}
                    options={userRole}
                    onSubmit={(e) => this.onSubmit(e)}
                    info={Betting.userRole}
                  />
                </div>
                <div className='col-md-4 col-lg-3 col-xl-2'>
                  <Input
                    title={"Agent Name/ID"}
                    name={"agentName"}
                    value={this.state.agentName}
                    placeholder={'Agent Name'}
                    setValue={(e) => this.handleAgent(e)}
                    ids={"agentName"}
                    info={"Filter out the data as per Agent Name/Id"}
                    show={true}
                  />
                </div>
                {
                  <div className='col-md-4 col-lg-3 col-xl-2'>
                    <div className="CMS-formGroup">
                      <div className="CMS-formLabel">
                        {BONUS_CONSTANTS.header_sport}
                        &nbsp;&nbsp;
                        <Tooltips info={Betting.sport} />
                      </div>
                      <MultipleSelectDDL
                        options={sportsData}
                        onSelect={(sports) => this.setSelected(sports)}
                        shouldReset={this.state.clearAll}
                        initialSelected={this?.props?.formData?.sportsSelected || []}
                      />
                    </div>
                  </div>
                }
                {this.state.gamingType.includes("VIRTUALS") && <div className='col-md-4 col-lg-3 col-xl-2'>
                  <div className='CMS-formGroup'>
                    <div className='CMS-formLabel'>
                      {"Provider Name"}
                      &nbsp;&nbsp;
                      <Tooltips info={Bonusing.providerName} />
                    </div>
                    <MultipleSelectDDL
                      options={this.props.virtualsProviderNames}
                      onSelect={(value) => this.handleSelectTridentProviders(value)}
                      shouldReset={this.state.resetVirtualProviders}
                      initialSelected={this?.props?.formData?.virtualProviders || []}
                    />
                  </div>
                </div>}
                {
                  this.props?.casinoType && this.state.gamingType === "CASINO" ?
                    Object.entries(this.props.casinoType).map(([key, value], ind) => {

                      const category = this.getCategory(key);
                      const inputValue = this.getInputValue(key) || [];
                      let states = [
                        this.state.CATEGORY,
                        this.state.GAME_NAME,
                        this.state.PROVIDER_NAME,
                        this.state.SUB_PROVIDER_NAME,
                      ]
                      return (
                        <div key={key} className="col-md-4 col-lg-3 col-xl-2">
                          <div className="CMS-formGroup">
                            <div className="CMS-formLabel">
                              {category?.label}
                              &nbsp;&nbsp;
                              <Tooltips info={category?.tooltip} />
                            </div>
                            <MultiSelectDDL
                              options={value || []}
                              setSelected={(val) => this.setCasinoSelected(val, key)}
                              ids={inputValue && inputValue?.length > 0 && inputValue[key]}
                              setIds={(value) => this.setCasinoSelected(value, key)}
                              selectedOnce={states[ind]}
                              clearAll={this.state.casinoClearAll}
                              setClear={() => this.setCasinoClear()}
                              betDDL
                            />
                          </div>
                        </div>
                      );
                    })
                    : null
                }

              </div>
              <div className='row'>
                <ButtonGroup
                  groupNames={["Search", "Reset"]}
                  idsearch={"BettingHistory"}
                  idreset={"BettingReset"}
                  submit={(e) => this.onSubmit(e)}
                  reset={(e) => this.resetButton(e)}
                />
              </div>
            </div>

            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={this.props.userData.data}
                  totalRecords={this.props.userData.totalRecords}
                  paginationFirstValue={this.props.paginationFirstValue}
                  paginationSecondValue={this.props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={this.props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={this.props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={this.state.csvData}
                  exportPDF={(val) => this.exportPDF(val)}
                  callApi={this.callApi}
                  csvFileName={"Sport Bet History Report"}
                  payloadData={this.props.betPayloadData}
                  enableAll={false}
                  allRecordsData={this.props.reportDocs}
                  reportsLoader={{ loader: this.props.reportsLoader, setLoader: setReportsLoader }}
                />
              </div>
              {!this.props.isVisible ? (
                <Sklton vLen={5} hLen={7} />
              ) : (
                <BetHistoryTable
                  fetchedData={this?.props?.getData}
                  view={this.state.view}
                  tableData={this.state.csvData}
                  sortables={betSortables}
                  sortingOrder={this.state.sortFields}
                  handleSortFields={(item, key) => this.handleSortFields(item, key)}
                  stakeTaxPermit={this.props.stakeTaxPermit}
                  winTaxPermit={this.props.winTaxPermit}
                  gamingType={this.state.gamingType}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userData: state.betHistorySliceReducer.userData,
    paginationFirstValue: state.betHistorySliceReducer.paginationFirstValue,
    paginationSecondValue: state.betHistorySliceReducer.paginationSecondValue,
    errorMessage: state.betHistorySliceReducer.errorMessage,
    getData: state.betHistorySliceReducer.userData.data,
    recordsShow: state.betHistorySliceReducer.recordsShow,
    userId: state.playersearch.userId,
    isVisible: state.betHistorySliceReducer.isVisible,
    sportsList: state.bonus.sportsList,
    sportsData: state.bonus.sportsData,
    formData: state.betHistorySliceReducer.formData,
    docsData: state.betHistorySliceReducer.docsData,
    reportsData: state.betHistorySliceReducer.reportsData,
    reportDocs: state.betHistorySliceReducer.reportDocs,
    reportsLoader: state.betHistorySliceReducer.reportsLoader,
    defaultSports: state.bonus.defaultSports,
    stakeTaxPermit: state.dashboard.stakeTaxPermission,
    winTaxPermit: state.dashboard.winTaxPermission,
    casinoType: state.betHistorySliceReducer.casinoType,
    casinoData: state.betHistorySliceReducer.casinoData,
    betPayloadData: state.betHistorySliceReducer.betPayloadData,
    virtualsProviderNames: state.betHistorySliceReducer.virtualsProviderNames,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BetHistory);
