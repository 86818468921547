import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Link } from "react-router-dom";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import {
  getKycDoc,
  getKycType,
} from "../primaryplayer/PrimaryPlayerSlice";
import { COMMON_CONSTANTS, KYC_CONSTANTS } from "../../sharedfiles/Constants";
import { getDateFormate, getDateTimeFormate } from "../../sharedfiles/helper";
import ConfirmDialog from "../Common/Dialog/ConfirmDialog";
import No__Data__Found from "../Common/table/No__Data__Found";
import { getKycUserList, kycApprovalAll, kycRejectAll, setIsVisible } from "./KycUserSlice";
import KycCommentBox from "./KycCommentBox";
import Tooltips from "../Common/Tooltip";
import Skelton from "../Common/skelton/Skelton";

const KycUserTable = (props) => {
  const dispatch = useDispatch();
  const [kycdialog, setKycdialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userId, setUserId] = useState("");
  const [urlPopup, setUrlPopUp] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [declineBox, setDeclineBox] = useState(false)

  const kycPendingList = useSelector((state) => state.kycusers.kycUserData);
  const kycType = useSelector((state) => state.primaryplayer.kycType);
  const isVisible = useSelector(
    (state) => state.kycusers.isVisible
  );

  useEffect(() => {
    dispatch(getKycUserList(0, 25, { kycStatus: "SUBMITTED" }));
    dispatch(getKycType());
    dispatch(setIsVisible(false));
  }, []);

  const kycHandlerDoc = (userId) => {
    setKycdialog(true);
    dispatch(getKycType());
    if (kycType.length > 0) {
      for (let i in kycType) {
        let kycdata = {
          kycType: kycType[i],
        };
        dispatch(
          getKycDoc(
            userId,
            props.paginationFirstValue - 1,
            props.paginationSecondValue,
            kycdata
          )
        );
      }
    }
  };
  const handleClick = (item, type) => {
    setUrlPopUp("IMAGE");
    setImageUrl(item);
  };
  const handlePopUpClose = () => {
    setImageUrl("");
    setUrlPopUp("");
  };
  const kycApproval = (id) => {
    setOpenDialog(true);
    setUserId(id);
  };

  const handleApproval = () => {
    dispatch(kycApprovalAll("KYC_PASSED", userId));
  };

  const kycReject = (id) => {
    // setOpenRejectbox(true);
    setDeclineBox(true)
    setUserId(id);
  };

  const handleReject = (msg) => {
    dispatch(kycRejectAll("REJECTED", userId, msg));
  };

  const kycCloseHandler = () => {
    setKycdialog(false);
  };
  const remainHeaders = [
    "ID",
    "Document Id",
    "Name",
    "DOB",
    "Status",
    "Updated Date",
    "Document",
  ];
  const headers = [
    "Customer ID",
    "User Name",
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "State",
    "Country",
    "Registration Date",
    "Documents",
    "KYC Approval",
  ];

  const KycInfo = [
    "Showing Customer ID",
    "Showing User Name",
    "Showing First Name",
    "Showing Last Name",
    "Showing Email",
    "Showing Phone Number",
    "Showing users State",
    "Showing Country",
    "Showing Registration Date",
    "Showing all uploaded Documents",
    "Showing KYC Approval options",
  ]
  const KycRemainInfo = [
    "Showing ID",
    "Showing Document Id",
    "Showing Name",
    "Showing DOB",
    "Showing Status of KYC",
    "Showing Updated Date",
    "Showing uploaded Document",
  ]

  return (
    <>
      <ConfirmDialog
        isKyc
        title={"KYC Approval"}
        message={"Do you want to Approve the Documents ?"}
        open={openDialog}
        cancel={() => setOpenDialog(false)}
        verify={() => handleApproval()}
        close={() => setOpenDialog(false)}
        key={"approval"}
        width={"sm"}
      />
      <Dialog__Main title={"Action Needed"} dOpen={declineBox} dClose={() => setDeclineBox(false)}>
        <KycCommentBox
          finish={(msg) => handleReject(msg)}
          close={() => setDeclineBox(false)}
        />
      </Dialog__Main>
      <Dialog__Main
        dOpen={urlPopup}
        dClose={handlePopUpClose}
        title={urlPopup == "IMAGE" && "Document"}
        width={"sm"}
      >
        <div
          style={
            urlPopup == "IMAGE"
              ? {
                height: "400px",
                msOverflow: "scroll",
                userSelect: "text",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
              : {}
          }
        >
          {urlPopup == "IMAGE" && <img src={imageUrl} />}
        </div>
      </Dialog__Main>
      <Dialog__Main
        title={"KYC Verification"}
        dOpen={kycdialog}
        dClose={kycCloseHandler}
        width={"lg"}
      >
        <div className="CMS-box">
          <div className="CMS-box__Header">
            <div className="CMS-box__title">{KYC_CONSTANTS.identity_docs}</div>
          </div>
          {props.identityDocs?.data ? (
            <div className="CMS-box">
              <div className="CMS-table CMS-table-triped custom_height_200">
                <table>
                  <thead>
                    <tr>
                      {remainHeaders.map((item, index) => (
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{item}</p>
                            <Tooltips info={`${KycRemainInfo[index]}`} />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {props.identityDocs.data != undefined &&
                      Object.keys(props.identityDocs.data).length > 0 &&
                      props.identityDocs?.data?.length > 0 ? (
                      props.identityDocs.data.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.docId}</td>
                            <td>
                              {`${item.firstName === null ? "" : item.firstName
                                }`}{" "}
                              {`${item.lastName === null ? "" : item.lastName}`}
                            </td>
                            <td>{item.dob}</td>
                            <td>{item.docStatus}</td>
                            <td>{getDateTimeFormate(item.updatedDate)}</td>
                            <td>
                              <button
                                className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                onClick={() => handleClick(item.docPath)}
                                style={{ marginBottom: "2px" }}
                              >
                                {COMMON_CONSTANTS.view_btn}
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="no_data_screen">
                        <td colSpan={11}>{KYC_CONSTANTS.nokycDocfound}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="CMS-box">
          <div className="CMS-box__Header">
            <div className="CMS-box__title">{KYC_CONSTANTS.address_docs}</div>
          </div>

          {props.addressDocs?.data ? (
            <div className="CMS-box">
              <div className="CMS-table CMS-table-triped custom_height_200">
                <table>
                  <thead>
                    <tr>
                      {remainHeaders.map((item, index) => (
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{item}</p>
                            <Tooltips info={`${KycRemainInfo[index]}`} />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {props.addressDocs.data != undefined &&
                      Object.keys(props.addressDocs.data).length > 0 &&
                      props.addressDocs?.data?.length > 0 ? (
                      props.addressDocs.data.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.docId}</td>
                            <td>
                              {`${item.firstName === null ? "" : item.firstName
                                }`}{" "}
                              {`${item.lastName === null ? "" : item.lastName}`}
                            </td>
                            <td>{item.dob}</td>
                            <td>{item.docStatus}</td>
                            <td>{getDateTimeFormate(item.updatedDate)}</td>
                            <td>
                              <button
                                className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                onClick={() => handleClick(item.docPath)}
                                style={{ marginBottom: "2px" }}
                              >
                                {COMMON_CONSTANTS.view_btn}
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="no_data_screen">
                        <td colSpan={11}>{KYC_CONSTANTS.nokycDocfound}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Dialog__Main>

      {!isVisible ? <Skelton vLen={5} hLen={7} /> : <div className="CMS-box">
        <div className="CMS-table CMS-table-triped">
          <table>
            <thead>
              <tr>
                {headers.map((item, index) => (
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{item}</p>
                      <Tooltips info={`${KycInfo[index]}`} />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {kycPendingList?.data?.length > 0 ? kycPendingList?.data?.map((user) => {
                return (
                  <tr>
                    <td>
                      {" "}
                      <Link
                        to={`/customerid/${user.userId}`}
                        style={{ color: "#2d91fc" }}
                      >
                        {user.userId}
                      </Link>
                    </td>
                    <td>{user.userName}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.state}</td>
                    <td>{user.country}</td>
                    <td>{getDateFormate(user.registrationDate)}</td>
                    <td>
                      <button
                        id="KYCbtn"
                        onClick={() => kycHandlerDoc(user.userId)}
                        className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                      >
                        {KYC_CONSTANTS.viewDoc_btn}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => kycApproval(user.userId)}
                        className={`CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusActive pointer ${props.viewOnlyuser ? "disable-button" : ""}`}
                      >
                        {KYC_CONSTANTS.header_approve}
                      </button>
                      <button
                        onClick={() => kycReject(user.userId)}
                        className={`CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusClosed pointer ${props.viewOnlyuser ? "disable-button" : ""}`}
                        style={{ marginTop: "5px" }}
                      >
                        {KYC_CONSTANTS.header_reject}
                      </button>
                    </td>
                  </tr>
                );
              }) :
                <No__Data__Found colSpan={11} />
              }
            </tbody>
          </table>
        </div>
      </div>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.playersearch.userId,
    kycData: state.primaryplayer.kycData,
    kycType: state.primaryplayer.kycType,
    kycStatus: state.primaryplayer.kycStatus,
    kycDoc: state.primaryplayer.kycDoc,
    kycDocAdd: state.primaryplayer.kycDocAdd,
    identityDocs: state.primaryplayer.identityDocs,
    addressDocs: state.primaryplayer.addressDocs,
    paginationFirstValue: state.playersearch.paginationFirstValue,
    paginationSecondValue: state.playersearch.paginationSecondValue,
    kycPermission: state.dashboard.kycPermission,
    viewOnlyuser: state.dashboard.viewOnlyuser,
  };
};
export default connect(mapStateToProps)(KycUserTable);
